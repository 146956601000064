/* 
bakcgoru clr : rgb(6, 40,68)
*/

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  background-color: white;
  box-sizing: border-box;
}

.app {
  position: relative;
}

/* common css begin */
.btn {
  display: inline-block;
  text-transform: uppercase;
  background-color: rgb(31, 218, 104); /*#38fba1;*/
  font-size: 1.15rem;
  font-weight: 700;
  color: white;
  letter-spacing: 0.8px;
  border-radius: 2rem;
  padding: 0.85rem 2.7rem 0.85rem 2.7rem;
  text-decoration: none;
  cursor: pointer;
}

.btn:hover {
  box-shadow: 0px 0px 20px -1px rgb(130, 148, 161);
}
.btn:active {
  box-shadow: 0px 0px 20px 1px rgb(130, 148, 161);
}

.logout-wrapper{
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: inline-block;
  align-items: center;
}
.logout-btn{
  margin-bottom: 30px;
  margin-left: 30px;
}

/* Loader */
.loader-wrapper{
  position: fixed;
  top:30%;
  left: 50%;
  z-index: 100000;
  transform: translate(-50%,-50%);
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(6, 40, 68);
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* common css end */

/* Builder Screen  start */
.builder__header {
  /* display: flex; */
  position: abolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 87px;
  padding: 10px;
  background-color: rgb(6, 40, 68);
}

.builder__header__text {
  position: absolute;
  text-transform: capitalize;
  color: white;
  letter-spacing: 0.4px;
  top: 43px;
  margin-left: 15px;
  font-size: 1.5rem;
  font-weight: 600;
  transform: translate(0, -50%);
}
.builder__header__close {
  position: absolute;
  color: white;
  top: 43px;
  font-size: 3.79rem;
  font-weight: 500;
  transform: translate(0, -50%);
  right: 25px;
}

.builder-main {
  position: relative;
  width: 100vw;
}

@media only screen and (max-width: 700px) {
  .builder-main {
    position: relative;
    width: 700px;
  }
  .builder__header {
    width: 700px;
  }
  body {
    width: 700px;
  }
}

.builder__nav {
  position: absolute;
  left: 0;
  width: 345px;
  background-color: rgb(247, 248, 249);
  padding-top: 7px;
  padding-right: 30px;
  padding-left: 15px;
  min-height: 100vh;
}
.builder__nav__header {
  text-transform: uppercase;
  font-size: 1.05rem;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: rgb(11, 84, 148);
  margin-bottom: 10px;
  margin-top: 23px;
}
.builder__nav__item {
  display: block;
  font-size: 1.15rem;
  text-align: left;
  padding-left: 0px;
  font-weight: 600;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 2px;
  letter-spacing: 0.4px;
  color: rgb(130, 148, 161);

  margin-bottom: 3px;
}
.builder__nav__item--active {
  background-color: white;
  color: rgb(6, 40, 68);
  box-shadow: -1px 2px 9px 2px rgb(214, 219, 223);
}
.builder__nav__item__icon--done {
  font-size: 1.15rem;
  margin-left: 4px;
  color: rgb(31, 218, 104);
}
.builder__nav__item--done {
  color: rgb(6, 40, 68);
}

.builder__nav__item__icon--hidden {
  visibility: hidden;
}

.builder__nav__line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(180, 190, 198);
  margin: 1.5rem 0;
  padding: 0;
}

.builder__content {
  margin-left: 345px;
  padding-left: 30px;
  padding-right: 30px;
  min-width: 700px;
}

.builder__content__app-logo {
  position: relative;
  display: flex;
  flex-flow: row;
  top: -78px;
  left: 50%;
  width: 270px;
  min-height: 90px;
  transform: translate(-50%, 0px);
  justify-content: space-between;
}
.builder__content__app-logo__box {
  padding-top: 13px;
  width: 95px;
  border-radius: 2px;
  min-height: 90px;
  text-align: center;
  background-color: rgb(244, 245, 246);
  box-shadow: 0px 3px 6px -1px rgb(228, 232, 235);
}
.builder__content__app-logo__box--active{
  box-shadow: 0px 20px 25px -10px rgb(228, 232, 235);
}
.builder__content__app-logo__img {
  display: flex;
  align-items: center;
  text-align: center;
  width: 62px;
  margin: auto;
  height: 62px;
  border-radius: 2px;
  background-color: rgb(180, 190, 198);
}
.builder__content__app-logo__img--active{
  background-color: rgb(244, 245, 246);
}

.builder__content__app-logo__img i {
  margin: auto;
  color: white;
}

.builder__content__app-logo__text {
  display: inline-block;
  margin-top: 10px;
  min-width: 60px;
  max-width: 70px;
  margin-bottom: 10px;
  word-wrap: break-word;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: rgb(130, 148, 161);
}

.builder__content__progress {
  position: relative;
  display: flex;
  flex-flow: column;
  top: -65px;
  left: 50%;
  transform: translate(-50%, 0px);
}
.builder__content__progress__bar {
  position: relative;
  display: flex;
  margin: auto;
  flex-flow: row;
  height: 30px;
  width: 650px;
  align-items: center;
  /* background-color: rgb(130, 148, 161); */
}

.builder__content__progress__circle {
  font-size: 1.25rem;
  color: rgb(180, 190, 198);
}

.builder__content__progress__circle--done {
  font-size: 1.25rem;
  color: rgb(6, 40, 68);
}

.builder__content__progress__circle--active {
  font-size: 2.1rem;
  color: rgb(31, 218, 104);
}

.builder__content__progress__line {
  width: 80px;
  height: 1px;
  border: 0;
  margin-left: -1px;
  margin-right: -1px;
  border-top: 2px solid rgb(180, 190, 198);
}

.builder__content__progress__line--done {
  border-top: 2px solid rgb(6, 40, 68);
}

.builder__content__progress__text {
  position: relative;
  font-size: 1.1rem;
  color: rgb(6, 40, 68);
  font-weight: 600;
  width: 650px;
  margin: auto;
}
.builder__content__progress__text__item {
  position: relative;
  display: inline-block;
  transform: translate(-50%, 0px);
}

.builder__content__header {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 40px;
  top: -18px;
  align-items: center;
}

.builder__content__header__step-text {
  display: block;
  color: rgb(6, 40, 68);
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
}
.builder__content__header__title {
  display: block;
  font-size: 2.35rem;
  font-weight: 650;
  color: rgb(6, 40, 68);
  text-align: center;
}

.builder__content__header__title__icon {
  color: rgb(180, 190, 198);
  margin-left: 10px;
  font-size: 1.7rem;
}

.builder__content__header__btn-back {
  border: 2px solid rgb(31, 218, 104);
  color: rgb(31, 218, 104);
  background-color: white;
  padding: 0.7rem 2.2rem 0.7rem 2.2rem;
}

.builder__content__header__btn-next {
  border: 2px solid rgb(31, 218, 104);
  color: rgb(31, 218, 104);
  background-color: white;
}

.builder__content__section {
  position: relative;
  padding: 30px;
}

.builder__content__section__item__label {
  display: inline-block;
  font-size: 1.35rem;
  font-weight: 600;
  text-align: left;
  color: rgb(6, 40, 68);
  padding: 4px 0 4px 0;
}
.builder__content__section__item {
  display: block;
  position: relative;
  margin: 10px auto;
  width: 500px;
}

.builder__content__section__item--trigger-account{
  display: block;
  position: relative;
  margin: 5px auto;
  width: 700px;
}

.builder__content__section__item--trigger-account--div{
  display: flex;
  flex-flow: row;
  align-items: center;
}
.builder__content__section__item--trigger-account--div--wrap{
  flex:1;
}
.builder__content__section__item--trigger-account--div .btn{
  margin-left: 17px;
}
.dropdown {
  position: relative;
  display: block;
}
.dropdown::before {
  position: absolute;
  content: " \025BE";
  top: 25px;
  right: 10px;
  font-size: 1.7rem;
  width: 20px;
  transform: translate(0px, -50%);
}
.dropdown input {
  height: 50px;
  border: 1px solid rgb(6, 40, 68);
  border-radius: 3px;
  width: 100%;
  padding: 20px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  z-index: 200;
}
.dropdown input:focus {
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.dropdown input::placeholder {
  color: rgb(133, 151, 163);
}

.dropdown__items_box {
  background-color: white;
  position: absolute;
  border-radius: 3px;
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 0px 40px 12px rgb(229, 233, 236);
  z-index: 100;
}

.dropdown__items_box--hide{
  display: none;
}

/* Builder Screen  end */

/* Login Screen Start */

.login__section {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px 25px 40px 25px;
}

@media only screen and (max-width: 960px) {
  .login__banner {
    display: none;
  }
}

.login__banner {
  height: 100%;
  width: 560px;
  clear: right;
}

.login__banner_img {
  height: 100%;
  width: 100%;
}

.login__form {
  flex: 1;
  height: 100%;
}
.login__form__wrap {
  position: relative;
  top: 50%;
  text-align: left;
  margin-left: 150px;
  transform: translate(0px, -50%);
}

.login__form__wrap h2 {
  font-size: 5rem;
  font-weight: 700;
  color: rgb(6, 40, 68);
  margin-bottom: 50px;
}

.login__form__wrap__label {
  display: block;
  font-size: 1.35rem;
  font-weight: 600;
  text-align: left;
  color: rgb(6, 40, 68);
  margin-bottom: 5px;
}
.login__form__wrap__input {
  display: block;
  outline: none;
  height: 50px;
  border: 1px solid rgb(180, 190, 198);
  border-radius: 3px;
  width: 400px;
  padding: 20px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  margin-bottom: 30px;
  font-style: normal;
  color: rgb(6, 40, 68);
}
.login__form__wrap__input:focus {
  border: 1px solid rgb(69, 247, 156);
}
.login__form__wrap__forgot {
  display: block;
  text-transform: uppercase;
  text-align: right;
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: 0.85rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  width: 400px;
  color: rgb(180, 190, 198);
  cursor: pointer;
}
.login__form__wrap__forgot:hover {
  color: rgb(6, 40, 68);
}

.login__form__btn {
  font-size: 1.3rem;
  color: rgb(18, 95, 161);
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 3rem;
  background-color: rgb(71, 255, 161);
}

/* Login Screen End */

/* index screen */
.index {
  background-color: rgb(6, 40, 68);
  display: flex;
  flex-flow: column;
  position: relative;
  min-height: 100vh;
}
.index__header {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 87px;
  padding: 15px;
  /* background-color: rgb(6, 40, 68); */
  align-items: center;
  justify-content: space-between;
}

.index__header__logo-box {
  height: 100%;
  padding: 10px;
}
.index__header__logo {
  height: 100%;
}

.index__header__btn {
  color: rgb(18, 95, 161);
  font-size: 1rem;
  background-color: rgb(71, 255, 161);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.index__container {
  display: flex;
  height: 100%;
  position: relative;
}
.index__container__nav {
  position: relative;
  width: 275px;
  display: block;
  /* min-height: 100vh; */
  padding: 0px 5px;
}

.index__container__nav__item {
  position: relative;
  display: flex;
  width: 100%;
  color: rgb(180, 190, 198);
  font-size: 1.4rem;
  font-weight: 600;
  padding: 15px 30px;
  border-radius: 3px;
  cursor: pointer;
}
.index__container__nav__item--active {
  background-color: rgb(40, 148, 240);
  color: white;
}
.index__container__nav__item__icon {
  font-size: 2.3rem;
}
.index__container__nav__item__text {
  padding-left: 15px;
  margin: auto 0px;
}
.index__container__main {
  flex: 1;
  background-color: white;
  border-radius: 5px;
  margin-right: 17px;
}
.index__container__logout {
  height: 64px;
  box-shadow: inset 0 1px 0 0 #0B5B98;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 275px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.index__container__logout:hover{
  background-color: #0B5B98;
}

.index__container__logout__avatar{
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  flex-flow: column;
  height: 32px;
  width: 32px;
  background-color: #B4BEC6;
  border-radius: 50%;
}
.index__container__logout__avatar span{
  margin: auto;
}
.index__container__logout__user{
  display: inline-block;
  margin-left: 22px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #B4BEC6;
}

.index__container__logout__user__popup{
  z-index: 10000;
  background-color: white;
  display: flex;
  flex-flow: row;
  padding: 1rem 2rem;
  border-radius: .5rem;
  position: absolute;
  align-items: center;
  font-size: 1.5rem;
  bottom:50px;
  left: 50px;
  font-weight: 600;
  width: 230px;
  height: 70px;
  box-shadow: 0px 0px 20px -1px rgb(130, 148, 161);
}

.index__container__logout__user__popup__text:hover{
color: rgb(133,151,163);
}
/* index screen*/


/* Common overwrites */
.btn--disabled {
  background-color: rgb(232, 235, 238);
  color: white;
  box-shadow: none;
}
.btn--disabled:hover{
  box-shadow: none;
}


/*************************************************
             NOTIFICATION ALERT
*************************************************/

.notification-wrapper__warning {
  width: 440px;
  border-radius: 4px;
  background-color: rgba(251, 89, 115, 0.81);
  box-shadow: 0 2px 30px 0 #272424;
  position: fixed;
  top: 30px;
  left: 40%;
  padding: 10px 15px;
  z-index: 100;
}

.notification-wrapper__warning__text {
  color: #ffffff;
  font-family: "Gotham Rounded", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

.notification-wrapper__success {
  width: 440px;
  border-radius: 4px;
  background-color: rgba(157, 218, 95, 0.8);
  box-shadow: 0 2px 30px 0 #272424;
  position: fixed;
  top: 30px;
  left: 40%;
  padding: 10px 15px;
  z-index: 100;
}

.notification-wrapper__success__text {
  color: #ffffff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

/*************************************************/

.integration-list{
  /* border-radius: 5px; */
}

.integration-list--header{
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 55px;
  background-color: rgb(247,248,249);
}
.integration-list--header--item{
  text-transform: uppercase;
  display: inline-block;
  margin-left: 30px;
  font-size: 1.15rem;
  font-weight: 700;
  height: 100%;
  letter-spacing: 0.05rem;
  color:rgb(133,151,163);
}
.integration-list--header--item--wrapper{
  margin-top: 20px;
  height: 1.15rem;
}
.integration-list--header--item--count {
  padding:0.15rem 0.8rem 0.15rem 0.8rem;
  background-color: rgb(133,151,163);
  color:white;
  font-size: 0.8rem;
  border-radius: 1rem;
  margin-left: 5px;
}
.integration-list--header--item__active{
  color:rgb(16,89,152);
  border-bottom: 3px solid rgb(71, 255, 161);
}
.integration-list--header--item__active .integration-list--header--item--count{
  background-color: rgb(16,89,152)
}

.integration-list--items{
  display: flex;
  flex-flow: row;
  height: 15rem;
  border-bottom: 1px solid rgb(180, 190, 198);
  padding-left: 100px;
  padding-right: 50px;
  justify-content: space-between;
}

.integration-list--items--wrapper{
  margin:auto 0px;
}

.integration-list--items--status{
  display: inline-block;
  border-radius: 1rem;
  background-color:rgb(16,89,152);
  color:white;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 0.2rem 0.6rem 0.2rem 0.6rem;
}

.integration-list--items--title{
  display: block;
  color:black;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.integration-list--items--last-update{
  display: block;
  color:rgb(180, 190, 198);
  font-weight: 600;
}

.integration-list--items--icon{
  position: "relative";
  margin:auto 0px;
  cursor: pointer;
}
.integration-list--items--icon :hover{
  text-shadow: 1px 4px 5px rgb(133,151,163);
}

.integration-list--items--icon i{
  margin-left: 30px;
}

/* ************************************ */

/* ************************* */
/* Builder  */
/* *************************** */

.new-integration-overlay{
  display: flex;
  flex-flow: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(5,40,68, 0.95);
}

.new-integration-overlay--close{
  position: fixed;
  top: 3rem;
  right: 3rem;
  font-size: 3rem;
  color:white;
  cursor: pointer;
}

.new-integration-overlay--form{
  text-align: center;
 margin: auto;
 width: 60%;
 color:white;
}

.new-integration-overlay--form--title{
  display: block;
  font-size:2.6rem;
  font-weight: 700;
  margin-bottom: 100px;
}
.new-integration-overlay--form--input{
  appearance: none;
  margin: auto;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(71, 255, 161);
  padding-bottom: 1rem;
  background-color: rgba(5,40,68, 0);
  font-size: 5rem;
  font-weight: 300;
  text-align: center;
  color: white;
  margin-bottom: 50px;
}

.new-integration-overlay--form .btn{
  font-size: 1.3rem;
  padding:1.5rem 4rem 1.5rem 4rem;
  border-radius: 2.9rem;
}

.select-active-item{
  display: flex;
  flex-flow: row;
  font-size: 1.35rem;
  color: rgb(7,41,69);
  align-items: center;
  cursor: pointer;
  margin-left: .5rem;
}

.trigger--dropdown--item{
  display: flex;
  flex-flow: row;
  padding: 10px 10px 10px 2px;
  margin: 4px 10px 4px 10px;
  font-size: 1.35rem;
  color: rgb(7,41,69);
  align-items: center;
  cursor: pointer;
}

.trigger--dropdown--item--img{
  height: 30px;
  width: 30px;
}

.trigger--dropdown--item--text{
  display: inline-block;
  margin-left: 4px;
  font-weight: 500;
  font-style: normal;
}

.connect-trigger-acnt--dropdown-item{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgb(217,222,226);
}
.connect-trigger-acnt--dropdown-item--text .title{
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(7,41,69);
  margin-bottom: 10px;
}

.connect-trigger-acnt--dropdown-item--text .updatedat{
  color: rgb(170,182,191);
  font-size: 1.15rem;
  text-transform: capitalize;
}

.connect-trigger-acnt--dropdown--header{
  display: block;
  font-size: 0.85rem;
  padding: 3px 10px 3px 10px;
  color: rgb(131,149,161);
  font-weight: 600;
  text-transform: capitalize;
  background-color: rgb(247,248,249);
}

.connect-trigger-acnt--dropdown-item .btn{
  padding: 8px 30px;
  background-color: white;
  border:2px solid rgb(180,190,198);
  color:rgb(180,190,198);
  font-weight: 700;
}

.trigger-select--dropdown--item{
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid rgb(217,222,226);
}

.trigger-select--dropdown--item .title{
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(7,41,69);
  margin-bottom: 10px;
}

.trigger-select--dropdown--item .secondary{
  color: rgb(170,182,191);
  font-size: 1.15rem;
  text-transform: capitalize;
}


.gocanvas-login{
  position: absolute;
  top:20%;
  left:50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-flow: column;
  align-items: center;
}

.gocanvas-login input {
  margin: 20px auto;
  height: 50px;
  border: 1px solid rgb(6, 40, 68);
  border-radius: 3px;
  width: 300px;
  padding: 20px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  z-index: 200;
}

.gocanvas-login input:focus {
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.gocanvas-login input::placeholder {
  color: rgb(133, 151, 163);
}

.gocanvas-login .btn{
  font-size: 1.5rem;
  font-weight: 500;
}

.dbc-login{
  position: absolute;
  top:5%;
  left:50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 100px;
}

.dbc-login input {
  margin: 10px auto;
  height: 50px;
  border: 1px solid rgb(6, 40, 68);
  border-radius: 3px;
  width: 300px;
  padding: 20px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
}

.dbc-login input:focus {
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.dbc-login  input::placeholder {
  color: rgb(133, 151, 163);
}

.dbc-login .btn{
  font-size: 1.5rem;
  font-weight: 500;
}

.dbc-login--img{
  margin-bottom: 20px;
}

.dbc-login--item__label {
  display: inline-block;
  font-size: 1.35rem;
  font-weight: 600;
  text-align: left;
  color: rgb(6, 40, 68);
  padding: 4px 0 4px 0;
}
/* Select Action */
.builder__content__section__item__input {
  height: 50px;
  border: 1px solid rgb(180, 190, 198);
  border-radius: 3px;
  width: 100%;
  padding: 20px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  z-index: 200;
}
.builder__content__section__item__input:focus {
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.builder__content__section__item__input::placeholder {
  color: rgb(133, 151, 163);
}

.field-mapping__add-field-btn{
  margin: auto;
}

/*************************************************/
/********            User List          *********/
/*************************************************/

.user-list{
  /* border-radius: 5px; */
  width: 500px;
}

.user-list--header{
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  height: 50px;
  background-color: rgb(247,248,249);
}
.user-list--header--item{
  text-transform: uppercase;
  display: inline-block;
  margin: auto 30px;
  font-size: 1.15rem;
  width: 100px;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0.05rem;
  color:#002443;
}
.user-list--item--value{
  display: inline-block;
  margin: auto 30px;
  font-size: 1.15rem;
  text-align: left;
  width: 100px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  word-break: break-word;
  color:#002443;
}

.user-list--header--item__name{
}

.user-list--header--item__email{
}
.user-list--header--item__role{
}
.user-list--header--item__action{
  text-align: right;
}
.user-list--header--item__icount{
}

.user-list--header--item__default{
}


.user-list--item--value__name{
  text-transform: capitalize;
}
.user-list--item--value__role{
  text-transform: capitalize;
}
.user-list--item--value__email{
  text-transform: lowercase;
}
.user-list--item--value__icount{
}

.user-list--item--value__edit{
  cursor: pointer;
}

.user-list--item--value__edit:hover{
  color: red;
  text-shadow: 1px 1px 5px #002443;
}

.user-list--item--value__action{
  display: inline-block;
  text-align: right;
  width: 100px;
}
.user-list--item--value__action i{
  display: inline;
  font-size: 1.7rem;
  margin-left: 25px;
  cursor: pointer;
}
.user-list--item--value__action i:hover{
  color: red;
}

.user-list--items{
  min-height: 50px;
  border-bottom: 1px solid #d8d8d8;
  display: inline-flex;
  align-items: center;

}


.new-user-overlay{
  display: flex;
  flex-flow: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background-color: rgba(5,40,68, 0.65);
}

.new-user-overlay--form{
  background-color: white;
  height: 480px;
  width: 600px;
  margin: auto;
  border-radius: 3px;
}
.new-user-overlay--form--header{
  height: 40px;
  background-color: rgb(247,248,249);
  border-radius: 3px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between
}

.new-user-overlay--form--header--text{
  font-size: 1.2rem;
  margin: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.new-user-overlay--form--header--icon{
  margin: 10px;
  font-size: 1.7rem;
  cursor: pointer;
  color: rgb(149,149,149);
}

.new-user-overlay--form--content{
  height: 360px;
  display: flex;
  flex-flow: column;
  font-size: 1.2rem;
}

.new-user-overlay--form--content--inputbox{
  display: block;
  padding:5px;
  margin: 5px auto;
}

.new-user-overlay--form--content--input{
  display: block;
  height: 3.5rem;
  width: 400px;
  font-size: 1.3rem;
  padding:0px 10px;
  border: 1px solid rgb(223, 223, 223);
}

.new-user-overlay--form--content--input:focus {
  outline: none;
  border: 1px solid rgb(223, 223, 223);
}

.new-user-overlay--form--content--label{
  width: 200px;
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
}

.new-user-overlay--form--content--dropdown{
  display: block;
  height: 3.5rem;
  width: 400px;
  font-size: 1.3rem;
}

.new-user-overlay--form--content--dropdown:focus{
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.new-user-overlay--form--content--dropdown .dropdown__items_box{
  width: 400px;
  padding:10px 5px;
  box-shadow: 0px 0px 3px 2px rgb(229, 233, 236);
}

.new-user-overlay--form--content--dropdown-item{
  padding:10px;
  cursor: pointer;
}

.builder__content__section__item__label--mand{
  color: red;
  font-size: 2rem;
}

.cus-input{
  display: flex;
  flex-flow: row;
  height: 50px;
  border: 1px solid rgb(180, 190, 198);
  padding-left: .3rem;
  border-radius: 3px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  background-color: white;
}

.cus-input--text{
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: inherit;
  flex: 1;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.cus-input--html{
  margin: auto 0.5rem;
}

.select-list-item{
  margin: 1rem;
}

.field-logo-wrapper{
  display: inline-flex;
  flex-flow: row;
  
  padding: .2rem;
  background-color: rgb(20,154,255); 
  border-radius: .2rem;
}
.field-logo--img{
  margin: auto .2rem;
  height: 2rem;
}
.field-logo--text--wrapper{
  display: inline-block;
  background-color: rgb(245, 246, 247);
  padding: .5rem 1rem;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  
}
.field-logo--text{
  display: inline;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
}

.field-formula--text{
  display: inline;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
}

.field-mapping-mode{
  margin: 0 100px;
  min-width: 500px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.field-mapping-mode__text{
  font-size: 2rem;
  font-weight: 500;
  color: rgb(165,176,186);
  margin: auto 0.2rem;
}
.field-mapping-mode__icon--off{
  font-size: 5rem;
  font-weight: 500;
  color: rgb(165,176,186);
  margin: auto 0.2rem;
}
.field-mapping-mode__icon--off-{
  font-size: 5rem;
  font-weight: 500;
  color: rgb(165,176,186);
  margin: auto 0.2rem;
}
.field-mapping-container{
  margin: 0 100px;
  min-width: 500px;
}
.field-mapping{
   margin-top: 10px; 

}


.field-mapping__wrapper{
  border: 1px solid rgb(209, 215, 219);
  flex-flow: column;
  padding: 20px;
}

.field-mapping__input__header{
  display: inline-block;
  margin-bottom: -1.1rem;
  padding: 0.6rem;
  font-size: 1.5rem;
  margin-left: 3rem;
  font-weight: 600;
  background-color: white;
  z-index: 200;
}


.field-mapping__input_wrapper{
  position:relative;
  margin-bottom:10px;
}
.field-mapping__input{
  height: 50px;
  border: 1px solid rgb(180, 190, 198);
  border-radius: 3px;
  width: 100%;
   margin: 0 auto; 
  padding: 20px; 
  padding-right: 50px;
  font-size: 1.7rem;
  font-style: italic;
  font-weight: 200;
  letter-spacing: 0.8px;
  /* z-index: 200; */
}
.field-mapping__input__formula{
  position:absolute;
  display: flex;
  right: 0px;
  top: 0px;
  border:none;
  height:48px;
  width:48px;
  outline:none;
  text-align:center;
  padding:.5rem;
  cursor: pointer;
  font-weight: 500;
  margin: 1px;
  background-color: rgb(245, 246, 247);
  border-radius: 3px;
}
.field-mapping__input__formula__text{
  font-size: 2rem;
  color: rgb(112, 130, 144);
  margin: auto;
}

.field-mapping__input:focus{
  outline: none;
  border: 1px solid rgb(69, 247, 156);
}

.field-mapping__label__wrapper{
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
}
.field-mapping__repeat__wrapper{
  display: flex;
  flex-flow: column;
  justify-content: left;
  margin: 2rem 0;
}
.field-mapping__repeat_check{
  display: flex;
  flex-flow: row;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}

.field-mapping__repeat_check__text{
  font-size: 1.35rem;
  font-weight: 600;
  text-align: left;
  color: rgb(167, 176, 185);
  margin-bottom: 1rem;
}

.field-mapping__label__logo{
  margin-left: .5rem;
  height:1.35rem;
}

.field-mapping__label{
  margin-left: .6rem;
  display: inline-block;
  text-transform: capitalize;
  font-size: 1.35rem;
  font-weight: 600;
  text-align: left;
  color: rgb(6, 40, 68);
  padding: 4px 0 4px 0;
}

.field-mapping__label__required{
  color:red;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: .5rem;
}

.field-mapping__btn-wrapper{
  padding: 2rem;
  text-align: center;
}

.field-mapping__add-btn{
  font-size: 1.3rem;
}


.generic-overlay{
  display: flex;
  flex-flow: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255,255,255,.2);
  overflow: scroll;
}

.add-new-field--close{
  display: inline-block;
  margin: auto 2rem;
  font-size: 3.5rem;
  color:black;
  cursor: pointer;
}

.add-new-field--header {
  /* display: flex; */
  display: block;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 87px;
  padding: 10px;
  align-items: center;
  text-align: right;
}
.add-new-field--main{
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  overflow: scroll;
  align-items: center;
}
.add-new-field--box{
  margin: auto;
  display: flex;
  flex-flow: column;
  background-color: white;
  height: 40rem;
  width: 40rem;
  border: solid 1px rgb(208,214,219);
  border-radius: .2rem;
  padding: 4rem;
}
.add-new-field--title{
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1rem auto;
}

.add-new-field--name{
  display: inline-block;
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 3rem;
}

.add-new-field__btn-wrapper{
  display: flex;
  flex-flow: row;
  margin: auto;
margin-top: 3rem;
}

.add-new-field__btn{
  margin: 1rem;
  /* width: 10rem;
  text-align: center; */
}

.formula-builder-overlay{
  display: flex;
  flex-flow: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  overflow: scroll;
}

.formula-builder-overlay--close{
  margin: auto 2rem;
  font-size: 3.5rem;
  color:black;
  cursor: pointer;
}

.formula-builder{
  height: inherit;
  width: inherit;
}

.formula-builder--header {
  /* display: flex; */
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 87px;
  padding: 10px;
  background-color: white;
  border-bottom: solid 1px rgb(208,214,219);
  align-items: center;
  justify-content: space-between;
}

.formula-builder--header--text{
  display: flex;
  width: 280px;
  height: 100%;
  border-right: solid 1px rgb(208,214,219);
  align-items: center;
  flex-flow: row;
}

.formula-builder--header--text span{
  color: #002443;
  font-size: 1.6rem;
  font-weight: 600;
  padding-left: 1.5rem;
  
}

.formula-builder--header--title{
  flex:1;
  min-width: 280px;
  margin: auto 2rem;
  font-size: 1.6rem;
  font-style: italic;
  color: rgb(121,139,153);
}

.formula-builder--main{
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.formula-tray{
  width: 290px;
  display: flex;
  flex-flow: column;
  background-color: rgb(245, 246, 247);
  
  height: 100%;
  overflow: auto;
}

.fields-tray{
  width: 290px;
  display: flex;
  flex-flow: column;
  background-color: #f7f8f9;
  padding: 2.5rem;
  height: 100%;
  overflow: auto;
}

.formula-tray--header{
  font-size: 1.2rem;
  font-weight: 700;
  color:rgb(40,90,148);
  margin-top: 2.4rem;
  margin-left: 2.4rem;
}
.trigger-field{
  display: inline-block;
}

.fields-tray--item{
  margin-top:1rem;
  padding: 0 0 0.5rem 0;
  border-bottom: solid 1px rgb(214,218,223);
  cursor: pointer;
}
.fields-tray--field-container{
  margin-top: 2rem;
}

.formula-tray--form-container{
  margin-top: 2rem;
  display: flex;
  flex-flow: column;
}

.formula-tag{
  color: rgb(40,90,148);
  font-size: 1.4rem;
  display: inline-flex;
  margin-top:2rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
  flex-flow: row;
  align-items: center;
  cursor: pointer;
}

.formula-tag--text{
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: .5rem;
  color: #002443;
}

.formula-tag--expanded{
  background-color: white;
  display: flex;
  flex-flow: column;
  margin:2rem .6rem 0 .6rem;
  box-shadow: 0 2px 7px 0 rgba(5,41,68, .2);
  border-radius: .3rem;
  padding-bottom: 1rem;
}

.formula-tag--title{
  color: #0b5b98;
  font-size: 1.4rem;
  display: inline-flex;
  margin-top:1rem;
  margin-right: 2.4rem;
  flex-flow: row;
  align-items: center;
  margin-left: 1.8rem;
}

.formula-tag--fomula{
  margin-left: 4.7rem;
  font-size: 1.4rem;
  margin-top: 1.6rem;
  cursor: pointer;
}

.formula-builder--body{
  display: flex;
  flex:1;
  min-width: 280px;
  padding: 2rem;
  flex-flow: row;
  align-items: baseline;
}
.formula-input--wrapper{
  display: flex;
  flex-flow: row;
  align-items: center;
  flex: 1;
}
.formula-input--html{
  display: inline-block;
  font-size: 1.5rem;
  font-style: italic;
  color: inherit;
  font-weight: 500;
  flex: 1;
}

.formula-input--text{
  display: inline-block;
  border: none;
  outline: none;
  height: 3rem;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 500;
  letter-spacing: .1rem;
  color: inherit;
  background-color: inherit;
  flex: 1;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.formula-text{
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 .3rem;
}
.comma-text{
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 .3rem;
}
.formula-plain-text{
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 .3rem;
}
.formula-field{
  display: inline-flex;
  margin: 0 .3rem;
}

.formula-input{
  display: inline-block;
  border: none;
  outline: none;
  height: 3rem;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 500;
  letter-spacing: .1rem;
  color: inherit;
  width:1rem;
  background-color: inherit;
  flex: 1;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.cursor{
  border-left: solid 1px black;
  animation: 900ms linear infinite condemned_blink_effect;
  height: 2rem;
}

@keyframes condemned_blink_effect {
  0% {
      visibility: hidden;
  }
  50% {
      visibility: hidden;
  }
  100% {
      visibility: visible;
  }
}